<template>
    <div class="mainBody">
        <div class="realData">
            <div class="topSearch">
                <div class="search" ref="other">
                    <el-form :inline="true" :model="pageParams">
                        <el-form-item label="点位名称">
                        <el-input v-model="pageParams.pointName" clearable class="search-input" placeholder="请输入" size="mini"  maxlength="30"></el-input>
                        </el-form-item>
                        <el-form-item label="状态">
                        <el-select v-model="pageParams.pointStatus" clearable filterable placeholder="请选择"  size="mini">
                            <el-option v-for="item in monitorPointStatus" :key="item.value" :label="item.label"
                                :value="item.value"></el-option>
                        </el-select>
                        </el-form-item>
                        <el-form-item>
                        <div class="search-q">
                            <el-button type="primary" @click="search">查询</el-button>
                            <el-button type="info" plain @click="reset">重置</el-button>
                        </div>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="search">
                    <el-form :inline="true" :model="pageParams">
                      <el-form-item>
                        <div class="search-q">
                            <el-button type="primary" @click="addItem" icon="el-icon-plus">新增</el-button>
                        </div>
                      </el-form-item>
                    </el-form>
                </div>
            </div>
            <div class="content" :style="{ height: height }">
                <el-table :height="height" border :data="list" style="width: 100%;">
                    <el-table-column prop="pointName" label="点位名称"> </el-table-column>
                    <el-table-column prop="pointCode" label="点位编号"> </el-table-column>
                    <el-table-column prop="description" label="监测位置描述"> </el-table-column>
                    <el-table-column prop="collectPointName" label="远传采集点">
                      <template slot-scope="{row}">
                        <div>{{row.collectPointName ? row.collectPointName : '-'}}</div>  
                      </template>
                    </el-table-column>
                    <el-table-column prop="dataTypeName" label="数据类型"></el-table-column>
                    <el-table-column prop="pointStatusName" label="状态"></el-table-column>
                    <el-table-column prop="operation" label="操作" width="233px" >
                      <template slot-scope="{ row }">
                        <el-button type="text" size="mini" @click="editItem(row)">编辑</el-button>
                        <el-button type="text" size="mini" :style="{color:row.pointStatus == 1?'#ff0000':'#57C290'}" @click="blockItem(row)">{{row.pointStatus == 1 ? '停用' : '启用'}}</el-button>
                        <el-button type="text" size="mini" @click="boundItem(row)" v-if="!row.collectPointId">绑定</el-button>
                        <el-button type="text" size="mini" @click="unbindItem(row)" style="color:#ff0000" v-if="row.collectPointId">解绑</el-button>
                      </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total,sizes,prev, pager, next" :page-size="pageParams.size"
                :current-page="pageParams.current" :page-sizes="[ 30, 50, 100]" :total="total"
                @size-change="handleSizeChange" @current-change="currentChange">
                </el-pagination>
            </div>
        </div>
        <!-- 新增编辑 -->
        <el-dialog :visible.sync="showoperate" :close-on-click-modal="false" width="340px" class="Dialogform">
            <template slot="title">
                <div class="dialogTitles">
                    <span></span> <b>{{ popeName }}</b>
                </div>
            </template>
            <el-form :inline="true" :model="addData" :rules="rules" ref="addDataForm">
                <el-form-item label="监测点位名称" label-position="top" prop="pointName">
                  <el-input v-model="addData.pointName" clearable class="search-input" placeholder="请输入" size="mini" maxlength="30"></el-input>
                </el-form-item>
                <el-form-item label="数据类型" label-position="top"  prop="dataType">
                  <el-select v-model="addData.dataType" clearable filterable placeholder="请选择"  size="mini">
                      <el-option v-for="item in dataTypeDict" :key="item.type" :label="item.name"
                                :value="item.type"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="所属站点" label-position="top"  prop="scadaStationId">
                <el-select v-model="addData.scadaStationId" clearable filterable placeholder="请选择"  size="mini">
                    <el-option v-for="item in SelectList" :key="item.value" :label="item.label"
                                :value="item.value"></el-option>
                </el-select>
                </el-form-item>
                <el-form-item label="重点检测" prop="important">
                  <el-select v-model="addData.important" placeholder="请选择"  size="mini">
                    <el-option v-for="item in scadaMonitorPointImportant" :key="item.value" :label="item.label"
                      :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="监控位置描述" label-position="top"  prop="description">
                  <el-input v-model="addData.description" clearable class="search-input" placeholder="请输入" size="mini" maxlength="30"></el-input>
                </el-form-item>
            </el-form>
            <div class="search-q">
                <el-button type="primary" @click="addsubmit" size="mini">确定</el-button>
                <el-button type="info" plain @click="showoperate= false" size="mini">取消</el-button>
            </div>
        </el-dialog>
        <!-- 远传采集点列表 -->
        <el-dialog :visible.sync="showogather" :close-on-click-modal="false"  class="Dialoggather">
          <template slot="title">
                <div class="dialogTitles">
                    <span></span> <b>远传采集点列表</b>
                </div>
            </template>
            <el-form :inline="true" :model="gatherParams">
              <el-form-item label="点位名称">
                <el-input v-model="gatherParams.name" clearable class="search-input" placeholder="请输入" size="mini" maxlength="30"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="gathersearch" size="mini">查询</el-button>
              </el-form-item>
            </el-form>
            <!-- highlight-current-row  @current-change="handleCurrentChange" -->
            <el-table :height="500" border :data="gatherlist" style="width: 100%;" >
                    <el-table-column prop="protocol" label="采集点来源"> </el-table-column>
                    <el-table-column prop="name" label="采集点名称"> </el-table-column>
                    <el-table-column prop="tableName" label="采集点编号"> </el-table-column>
                    <el-table-column prop="dataTypeName" label="数据类型"> </el-table-column>
                    <el-table-column label="操作" width="70">
                      <template slot-scope="{ row }">
                        <el-radio v-model="selectVal" :label="row">&nbsp;</el-radio>
                      </template>
                    </el-table-column>
                </el-table>
                <div class="search-q">
                    <el-button type="info" plain @click="showogather = false" size="mini">取消</el-button>
                    <el-button type="primary" @click="submit" size="mini">确认</el-button>
                </div>
        </el-dialog>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import scadaApi from "@/apis/scadaApi";
export default {
    name: 'pointManage',
    components: {},
    data() {
        return {
            pageParams:{
                size: 100,
                current:1,
                dataFlag:'leakWarn'
            },
            total:0,
            height:0,
            list:[],
            showoperate:false,
            popeName:'新增',
            showogather:false,
            gatherParams:{
              current:0,
              size:-1
            },
            gatherlist:[],
            addData:{
              // 1scada 2泄露报警
              pointSource:2
            },
            rules:{
              pointName:[{ required: true, message: '请填写检测点位名称', trigger: 'blur' }],
              // description:[{ required: true, message: '请填写监控位置描述', trigger: 'blur' }],
              dataType:[{ required: true, message: '请选择数据类型', trigger: 'change'  }],
              important: [{ required: true, message: '请选择重点检测', trigger: 'change' }],
              // scadaStationId:[{ required: true, message: '请选择场站', trigger: 'change'  }],
            },
            dataTypelists:[],
            SelectList:[],
            pointId:'',
            acquisitionId:'',
            selectVal:null
        }
    },
    computed: {
      ...mapState('dict', ['monitorPointStatus','dataTypeDict','scadaMonitorPointImportant'])
    },
    mounted(){
      this.getDropdown('monitorPointStatus,scadaMonitorPointImportant')
      this.$nextTick(() => {
          this.height = `calc(100% - ${this.$refs.other.scrollHeight}px)`
      })
      this.getlist(1)
    },
    methods: {
      ...mapActions('dict', ['getDropdown','getDict1']),
      /**
       * 获取列表
       */
        getlist(val){
          if(val) {
            this.pageParams.current = val
          }
          scadaApi.addSelectLists(this.pageParams).then(res => {
            if(res.code==200) {
              this.list = res.data.list
              this.total = res.data.total
            }
          })
        },
        /**
         * 查询
         */
        search(){
          this.getlist(1)
        },
        /**
         * 重置
         */
        reset(){
          this.pageParams={
              size: 100,
              current:1,
              dataFlag:'leakWarn'
          },
          this.getlist(1)
        },
        /**
         * 获取类型字典/获取场站
         */
        getdict(){
          this.getDict1({parentCode:'300081'})
          scadaApi.getStationOptions().then(res => {
            if(res.code == 200) {
              this.SelectList = res.data
            }
          })
        },
        /**
         * 新增（弹窗）
         */
        addItem(){
          this.addData = {
            pointSource:2
          }
          this.getdict()
          this.popeName = '新增'
          this.showoperate = true
          if(this.$refs.addDataForm){
            this.$refs.addDataForm.resetFields();
          }
        },
        /**
         * 编辑（弹窗）
         */
        async editItem(row){
          // 场站下拉
          await this.getdict()
          this.showoperate = true
          if(this.$refs.addDataForm){
            this.$refs.addDataForm.resetFields();
          }
          // 查询单条数据
          await scadaApi.getPointById({id:row.id}).then(res => {
            if(res.code == 200) {
              this.addData = res.data
              
            }
          })
          this.popeName = '编辑'
          
        },
        /**
         * 新增/编辑（确认）
         */
        addsubmit(){
          let that = this
          this.$refs['addDataForm'].validate((valid) => {
            if (valid) {
              if(this.popeName == '新增') {
                scadaApi.addinsertMonitorPoints(this.addData).then(res => {
                  if(res.code == 200) {
                    that.$message.success('新增成功')
                    that.showoperate = false
                    that.search()
                  }
                })
              } else {
                scadaApi.updateinsertMonitorPoints(this.addData).then(res => {
                  if(res.code == 200) {
                    that.$message.success('编辑成功')
                    that.showoperate = false
                    that.search()
                  }
                })
              }
            }
          })
        },
        submit(){

        },
        /**
         * 停用/启用
         */
        blockItem(row){
          // 1启用2停用
          let data ={
            id:row.id,
            pointStatus:row.pointStatus == 1 ? 2 : 1
          }
          let that = this
          this.$confirm(row.pointStatus == 2 ? '监测点将被启用':'监测点将被停用' +', 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            scadaApi.settogglePointStatus(data).then(res => {
              if(res.code==200) {
                that.$message.success('操作成功!');
                that.getlist()
              }
            })
          }).catch(error => {
            this.$message({
              type: 'info',
              message: '已取消操作'
            });          
          });
        },
        /**
         * 绑定
         */
        boundItem(row){
          this.gatherParams={
              current:0,
              size:-1
            }
          // 监测点id
          this.pointId = row.id
          this.gathersearch()
          this.selectVal = null
          this.showogather = true
        },
        /**
         * 解绑
         */
        unbindItem(row){
          let that = this
          scadaApi.unbindCollectPoint({id:row.id}).then(res=> {
            if(res.code == 200){
              that.$message.success('解绑成功')
              that.getlist()
            }
          })
        },
        /**
         * 远传采集点查询
         */
        gathersearch(){
          scadaApi.getpageNoBind(this.gatherParams).then(res => {
            if(res.code== 200){
              this.gatherlist = res.data.records
            }
          })
        },
        /**
         * 绑定采集点单选事件
         */
        handleCurrentChange(row){
          // 采集点id
          if(row){
            this.acquisitionId = row.id
          }
        },
        /**
         * 绑定确认事件
         */
        submit(){
          let that = this
          if (!this.selectVal) return this.$message.error('请选择采集点')
          let data = {
            id:this.pointId,
            collectPointId:this.selectVal.id
            // collectPointId:this.acquisitionId
          }
          scadaApi.bindCollectPoint(data).then(res => {
            if(res.code == 200) {
              that.$message.success('绑定成功')
              that.showogather = false
              that.getlist()
            }
          })
        },
        /**
         * 页码条数
         */
        handleSizeChange(e){
          this.pageParams.size = e
          this.getlist(1)
        },
        /**
         * 分页
         */
        currentChange(e){
          this.getlist(e)
        }
    }
}
</script>

<style lang="scss" scoped>
.dialogTitles{
    display: flex;
    align-items: center;
    color: #0f0f0f;
    font-size: 16px;
    margin-top: 4px;
    b{
        margin-right: 10px;
    }
    span{
        display: inline-block;
        background-color: #1082FF;
        width: 4px;
        height: 16px;
        margin: 0 10px;
    }
}
::v-deep .search {
  .el-form-item__content {
    line-height: 43px;
  }

  .el-form-item {
    margin-bottom: 0;
    margin-right: 20px;
  }

  .el-form-item__label {
    color: #0F0F0F;
    padding-right: 5px;
    line-height: 43px;
  }

  .el-input__inner {
    height: 28px;
  }

  .el-input__suffix {
    top: -3px
  }
  .el-button{
    padding: 6px 10px;
  }
}
.mainBody {
  padding: 10px;
  height: calc(100vh - 175px);

  .realData {
    background: #fff;
    height: 100%;
    position: relative;

    .topSearch {
      border-bottom: 1px solid #E3E3E3;
      display: flex;
      justify-content: space-between;


      .search {
        margin-left:20px;
      }
    }
  }
}
.content {
    width: 100%;
    // height: calc(100% - 45px);
    padding: 20px;
    padding-right: 0;
    box-sizing: border-box;
    background: #fff;
}
::v-deep .Dialogform{
  // position: relative;
  .el-dialog__body{
    padding:10px 20px 60px;
  }
  .el-form-item{
    // margin-bottom:0;
    margin-bottom:12px;
    width: 100%;
    .el-form-item__label{
      line-height: 22px;
    }
    .el-form-item__content{
      width: 100%;
    }
    .el-select{
      width: 100%;
    }
  }
  .el-form-item__error{
    padding-top: 0;
  }
  .search-q{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    line-height: 50px;
    text-align: center;
    border-top: 1px solid #EBEBEB;
  }
}
.Dialoggather{
  .search-q{
    margin-top: 10px;
    text-align: right;
  }
}
</style>